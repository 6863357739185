import { createSlice } from "@reduxjs/toolkit";
import { distribuicaoPorIdadeRequest } from "../../actions/dashboards/dashboardsActions";

const distribuicaoPorIdadeSlice = createSlice({
  name: "distribuicaoPorIdade",
  initialState: {
    distribuicaoPorIdade: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(distribuicaoPorIdadeRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(distribuicaoPorIdadeRequest.fulfilled, (state, action) => {
        state.distribuicaoPorIdade = action.payload;
        state.loading = false;
      })
      .addCase(distribuicaoPorIdadeRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default distribuicaoPorIdadeSlice.reducer;
