import axios from "axios";

const api = axios.create({
  baseURL: "/api",
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const expiration = localStorage.getItem("tokenExpiration");

  if (token && expiration) {
    const currentTime = new Date().getTime();
    if (currentTime > parseInt(expiration)) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

export async function verificaStatusServidor() {
  try {
    const response = await api.get("/server/status");
    const { status } = response.data;
    if (!status) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

//----------------Agendamentos----------------//

export async function getAgendamento(id) {
  try {
    const response = await api.get(`/agendamentos.show/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAgendamentos() {
  try {
    const response = await api.get("/agendamentos");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addAgendamento(data) {
  try {
    const response = await api.post("/agendamentos", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updAgendamento(data) {
  try {
    const response = await api.put("/agendamentos", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function dltAgendamento(id) {
  try {
    const response = await api.delete(`/agendamentos/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

//----------------Agendamentos----------------//

//-----------------Consultas-----------------//

export async function getConsulta(id) {
  try {
    const response = await api.get(`/consultas.show/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getConsultas() {
  try {
    const response = await api.get("/consultas");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getConsultasPaciente(idPaciente) {
  try {
    const response = await api.get(`/consultas.paciente/${idPaciente}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addConsulta(data) {
  try {
    const response = await api.post("/consultas", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updConsulta(data) {
  try {
    const response = await api.put("/consultas", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function dltConsulta(id) {
  try {
    const response = await api.delete(`/consultas/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

//-----------------Consultas-----------------//

//-----------------Pacientes-----------------//

export async function getPaciente(id) {
  try {
    const response = await api.get(`/pacientes.show/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getPacientes() {
  try {
    const response = await api.get("/pacientes");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addPaciente(data) {
  try {
    const response = await api.post("/pacientes", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updPaciente(data) {
  try {
    const response = await api.put("/pacientes", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function dltPaciente(id) {
  try {
    const response = await api.delete(`/pacientes/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

//-----------------Pacientes-----------------//

//-----------------Dashboards----------------//

export async function getAntecedentesPessoais() {
  try {
    const response = await api.get("/dashboards/antecedentesPessoais");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCondicoesOpto() {
  try {
    const response = await api.get("/dashboards/condicoesOpto");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getConsultasMes() {
  try {
    const response = await api.get("/dashboards/consultasMes");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDistribuicaoPorIdade() {
  try {
    const response = await api.get("/dashboards/distribuicaoPorIdade");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMedicamentos() {
  try {
    const response = await api.get("/dashboards/medicamentos");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSintomas() {
  try {
    const response = await api.get("/dashboards/sintomas");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTipoLente() {
  try {
    const response = await api.get("/dashboards/tipoLente");
    return response.data;
  } catch (error) {
    throw error;
  }
}

//-----------------Dashboards----------------//

//-----------------Usuários-----------------//

export async function getUsuario(id) {
  try {
    const response = await api.get(`/usuarios.show/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUsuarios() {
  try {
    const response = await api.get("/usuarios");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addUsuario(data) {
  try {
    const response = await api.post("/usuarios", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updUsuario(data) {
  try {
    const response = await api.put("/usuarios", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function dltUsuario(id) {
  try {
    const response = await api.delete(`/usuarios/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

//-----------------Usuários-----------------//

//---------------Login/Logout---------------//

export async function verificaLogin(data) {
  try {
    const response = await api.post("/usuarios/login", data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function verificaLogout(data) {
  const token = localStorage?.getItem("token")?.slice(1, -1);
  try {
    const response = await api.get("/usuarios/destroytoken", {
      headers: { token: token },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

//---------------Login/Logout---------------//
