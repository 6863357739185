import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { BasicContainer } from "../../common/components/BasicContainer";
import { DynamicHeader } from "../../components/DynamicHeader";
import { BarChart } from "../../components/Chart/BarChart";
import { consultasMesRequest } from "../../redux/actions/dashboards/dashboardsActions";

export const Dashboards = () => {
  const dispatch = useDispatch();

  const consultasMes = useSelector(
    (state) => state.consultasMesReducer?.consultasMes
  );

  useEffect(() => {
    dispatch(consultasMesRequest());
  }, [dispatch]);

  const dataConsultasMes = useMemo(() => {
    if (consultasMes && consultasMes.length > 0) {
      return {
        labels: consultasMes.map((item) => item.numeroMes),
        datasets: [
          {
            label: "Consultas por Mês",
            data: consultasMes.map((item) => item.quantidade),
            backgroundColor: "rgba(54, 162, 235, 0.6)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      };
    }
    return null;
  }, [consultasMes]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function () {
            return "Consultas:";
          },
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const mes = consultasMes[index].mes;
            const quantidade = consultasMes[index].quantidade;
            return `${mes}: ${quantidade}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <DynamicHeader
        onClickHandler={"goToFormPage"}
        buttonText={"Adicionar"}
        title={"Relatórios"}
        visibility={false}
      />
      <BasicContainer>
        <Grid container spacing={2}>
          {dataConsultasMes && (
            <BarChart
              title="Consultas x Mês"
              data={dataConsultasMes}
              options={options}
            />
          )}
        </Grid>
      </BasicContainer>
    </>
  );
};
