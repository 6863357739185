import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Typography,
  Paper,
  Box,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import CloseIcon from "@mui/icons-material/Close";
import { color } from "../../../styles/theme/colors/colors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { CustomModal } from "../../CustomModal";
import { decryptUser } from "../../../services/auth";
import { formatTelefone } from "../../../functions/formatters";

export const EventTooltip = ({ event, onEdit, onDelete, onClose, style }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const user = decryptUser();
  const [positionStyle, setPositionStyle] = useState({});

  const goToFormPage = () => {
    navigate("/consultas/adicionar", { state: { event } });
  };

  const goToDelete = () => {
    setIsModalVisible(true);
  };

  const handlerModalCancelClick = () => {
    setIsModalVisible(false);
  };

  async function handlerModalDeleteClick() {
    onDelete(event);
    setIsModalVisible(false);
  }

  const handleMouseLeave = () => {
    if (!isModalVisible) {
      onClose();
    }
  };

  useEffect(() => {
    const adjustPosition = () => {
      const tooltipHeight = 200;
      const tooltipWidth = 300;

      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;

      const newTop =
        style.top + tooltipHeight > screenHeight
          ? screenHeight - tooltipHeight - 10
          : style.top;

      const newLeft =
        style.left + tooltipWidth > screenWidth
          ? screenWidth - tooltipWidth - 10
          : style.left;

      setPositionStyle({
        top: isMobile ? "50%" : newTop,
        left: isMobile ? "50%" : newLeft,
        transform: isMobile ? "translate(-50%, -50%)" : "none",
      });
    };

    adjustPosition();
  }, [isMobile, style.top, style.left]);

  return (
    <>
      {isModalVisible && (
        <CustomModal
          clickCancel={handlerModalCancelClick}
          clickConfirm={handlerModalDeleteClick}
          title={"Confirmar exclusão?"}
        />
      )}
      <Paper
        style={{
          ...style,
          ...positionStyle,
          padding: "0px",
          position: "absolute",
          zIndex: 999,
          width: isMobile ? "80vw" : "auto",
        }}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: color.primary_light,
            padding: "10px",
          }}
        >
          <Typography variant="h6" style={{ color: "#fff" }}>
            {event.nome_temporario ||
              event.paciente_nome + " " + event.paciente_sobrenome}
          </Typography>
          <Box>
            <IconButton
              style={{ color: "#fff", padding: "4px" }}
              onClick={() => onEdit(event)}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              style={{ color: "#fff", padding: "4px" }}
              onClick={goToDelete}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              style={{ color: "#fff", padding: "4px" }}
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: "10px",
          }}
        >
          <Box display="flex" alignItems="center" mb={1}>
            <AccessTimeIcon style={{ color: "#fff" }} />
            <Typography
              variant="subtitle1"
              style={{ color: "#fff", marginLeft: "5px" }}
            >
              {new Date(event.start).toLocaleTimeString()} -{" "}
              {new Date(event.end).toLocaleTimeString()}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <PhoneIcon style={{ color: "#fff" }} />
            <Typography
              variant="subtitle1"
              style={{ color: "#fff", marginLeft: "5px" }}
            >
              {formatTelefone(event.telefone)}
            </Typography>
          </Box>
          {user.nivelAcesso <= 2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={goToFormPage}
              endIcon={<PlayArrowIcon />}
              sx={{ mt: 1 }}
            >
              Iniciar Consulta
            </Button>
          )}
        </Box>
      </Paper>
    </>
  );
};
