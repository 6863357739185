import React, { createContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { createStore } from "./redux";

import { LayoutStructure } from "./components/LayoutStructure";
import { color } from "./styles/theme/colors/colors";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { ptBR } from "@mui/x-data-grid";

import { Login } from "./components/Login";
import { Dashboards } from "./pages/Dashboards";
import { Agendamentos } from "./pages/Agendamentos";
import { Consultas } from "./pages/Consultas";
import { FormConsulta } from "./components/Consulta/FormConsulta";
import { DetalhesConsulta } from "./components/Consulta/DetalhesConsulta";
import { Pacientes } from "./pages/Pacientes";
import { FormPaciente } from "./components/Paciente/FormPaciente";
import { Usuarios } from "./pages/Usuarios";
import { FormUsuario } from "./components/Usuario/FormUsuario";
import { Error404 } from "./components/Error404";

export const Context = createContext({});

const checkTokenValidity = () => {
  const token = localStorage?.getItem("token")?.slice(1, -1);
  const expiration = localStorage?.getItem("tokenExpiration");

  if (!token || !expiration) {
    return false;
  }

  const currentTime = new Date().getTime();
  if (currentTime > parseInt(expiration)) {
    localStorage.clear();
    return false;
  }

  return true;
};

const ProtectedRoute = () => {
  const isTokenValid = checkTokenValidity();

  if (!isTokenValid) {
    return <Navigate to="/login" replace />;
  }

  return <LayoutStructure />;
};

const App = () => {
  const { store } = createStore();

  const theme = createTheme(
    {
      palette: {
        primary: {
          light: `${color.primary_light}`,
          main: `${color.primary_pure}`,
          dark: `${color.primary_dark}`,
          contrastText: `${color.neutral_white}`,
        },
        background: {
          default: `${color.neutral_light}`,
        },
      },
      typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(","),
        button: {
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "1rem",
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 4,
              maxHeight: "35px",
            },
          },
        },
      },
    },
    ptBR
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReduxProvider store={store}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<Agendamentos />} />
            <Route path="relatorios" element={<Dashboards />} />
            <Route path="consultas">
              <Route index element={<Consultas />} />
              <Route path="adicionar" element={<FormConsulta />} />
              <Route path="editar" element={<FormConsulta />} />
            </Route>
            <Route path="pacientes">
              <Route index element={<Pacientes />} />
              <Route path="adicionar" element={<FormPaciente />} />
              <Route path="editar" element={<FormPaciente />} />
            </Route>
            <Route path="usuarios">
              <Route index element={<Usuarios />} />
              <Route path="adicionar" element={<FormUsuario />} />
              <Route path="editar" element={<FormUsuario />} />
            </Route>
            <Route path="*" element={<Error404 />} />
          </Route>
          <Route path="/detalhes-consulta" element={<DetalhesConsulta />} />
        </Routes>
      </ReduxProvider>
    </ThemeProvider>
  );
};

export default App;
