import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConsultasMes } from "../../../services/api";

export const consultasMesRequest = createAsyncThunk(
  "dashboards/fetch",
  async (_, thunkAPI) => {
    try {
      const response = await getConsultasMes();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
