import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Tooltip,
  Legend,
  Filler,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";
import { Card } from "../../../common/components/Card";
import { Loading } from "../../../common/components/Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Tooltip,
  Legend,
  Filler,
  SubTitle
);

export const BarChart = ({ title, data, options }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <Card>
      <Typography variant="h6" align="center">
        {title}
      </Typography>
      {isLoading ? <Loading /> : <Bar options={options} data={data} />}
    </Card>
  );
};
