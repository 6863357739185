import styled from "styled-components";
import { color } from "../../../../styles/theme/colors/colors";

export const Container = styled.div`
  background-color: ${color.neutral_white};
  min-width: 300px;
  width: 500px;
  max-height: 300px;
  margin: 16px;
  padding: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  display: grid;
  justify-content: center;

  @media (max-width: 768px) {
    min-width: 250px;
  }

  @media (max-width: 1024px) {
    width: 500px;
  }

  @media (min-width: 1025px) {
    width: 500px;
  }

  @media (min-width: 1920px) {
    width: 500px;
  }
`;
