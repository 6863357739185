import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAntecedentesPessoais,
  getCondicoesOpto,
  getConsultasMes,
  getDistribuicaoPorIdade,
  getMedicamentos,
  getSintomas,
  getTipoLente,
} from "../../../services/api";

export const antecedentesPessoaisRequest = createAsyncThunk(
  "dashboards/fetchAntecedentesPessoais",
  async (_, thunkAPI) => {
    try {
      const response = await getAntecedentesPessoais();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const condicoesOptoRequest = createAsyncThunk(
  "dashboards/fetchCondicoesOpto",
  async (_, thunkAPI) => {
    try {
      const response = await getCondicoesOpto();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const consultasMesRequest = createAsyncThunk(
  "dashboards/fetchConsultasMes",
  async (_, thunkAPI) => {
    try {
      const response = await getConsultasMes();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const distribuicaoPorIdadeRequest = createAsyncThunk(
  "dashboards/fetchDistribuicaoPorIdade",
  async (_, thunkAPI) => {
    try {
      const response = await getDistribuicaoPorIdade();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const medicamentosRequest = createAsyncThunk(
  "dashboards/fetchMedicamentos",
  async (_, thunkAPI) => {
    try {
      const response = await getMedicamentos();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sintomasRequest = createAsyncThunk(
  "dashboards/fetchSintomas",
  async (_, thunkAPI) => {
    try {
      const response = await getSintomas();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const tipoLenteRequest = createAsyncThunk(
  "dashboards/fetchTipoLente",
  async (_, thunkAPI) => {
    try {
      const response = await getTipoLente();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
