import { createSlice } from "@reduxjs/toolkit";
import { consultasMesRequest } from "../../actions/dashboards/dashboardsActions";

const consultasMesSlice = createSlice({
  name: "consultasMes",
  initialState: {
    consultasMes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(consultasMesRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(consultasMesRequest.fulfilled, (state, action) => {
        state.consultasMes = action.payload;
        state.loading = false;
      })
      .addCase(consultasMesRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export default consultasMesSlice.reducer;
