export const tiposAcesso = () => [
  { id: 1, nome: "Administrador" },
  { id: 2, nome: "Médico" },
  { id: 3, nome: "Auxiliar" },
];

export const getTiposAcesso = (value) => {
  var arr = ["Administrador", "Médico", "Auxiliar"];
  return arr[value - 1];
};

export const tiposGenero = () => [
  { id: 1, nome: "Masculino" },
  { id: 2, nome: "Feminino" },
];

export const getTiposGenero = (value) => {
  var arr = ["Masculino", "Feminino"];
  return arr[value - 1];
};
