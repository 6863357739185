import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import { Title } from "../../../common/components/Title";
import { decryptUser } from "../../../services/auth";
import logo from "../../../assets/logo.webp";

const Cabecalho = ({ medico, data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box sx={{ width: "100%", textAlign: "center", position: "relative" }}>
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
        >
          Detalhes da Consulta
        </Typography>
        <Grid container spacing={2} sx={{ flexDirection: "column" }}>
          <Grid item>
            <Typography
              variant="body1"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <PersonIcon sx={{ marginRight: "5px" }} />
              Médico: {medico}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <AccessTimeIcon sx={{ marginRight: "5px" }} />
              Data: {data}
            </Typography>
          </Grid>
        </Grid>
        <img
          src={logo}
          alt="Logo do Consultório"
          style={{
            width: 200,
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      </Box>
    </Box>
  );
};

const DetalhesMedicamentos = ({ title, detalhesConsulta }) => {
  const items = [
    { label: "Ansiedade", checked: detalhesConsulta.medicamentos_ansiedade },
    { label: "Colesterol", checked: detalhesConsulta.medicamentos_colesterol },
    { label: "Coração", checked: detalhesConsulta.medicamentos_coracao },
    { label: "Depressão", checked: detalhesConsulta.medicamentos_depressao },
    { label: "Glicose", checked: detalhesConsulta.medicamentos_glicose },
    { label: "Pressão Alta", checked: detalhesConsulta.medicamentos_pa },
    { label: "Tireoide", checked: detalhesConsulta.medicamentos_tireoide },
    { label: "Outro", checked: detalhesConsulta.medicamentos_outro },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Title>{title}</Title>
        </Grid>
        {items.map((item, index) => (
          <Grid item xs={6} md={1} key={index}>
            <Typography variant="body1" component="p">
              {item.label}
            </Typography>
            <FormControlLabel
              control={<Switch disabled checked={item.checked} />}
              label=""
              style={{ marginLeft: "auto" }}
            />
          </Grid>
        ))}
        {detalhesConsulta.nome_medicamento_outro && (
          <Grid item xs={6} md={4}>
            <Typography variant="body1" component="p">
              Nome:
            </Typography>
            {detalhesConsulta.nome_medicamento_outro}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const DetalhesSintomas = ({ title, detalhesConsulta }) => {
  const items = [
    { label: "Ardência", checked: detalhesConsulta.sintomas_ardencia },
    { label: "Coceira", checked: detalhesConsulta.sintomas_coceira },
    { label: "Dif. Visão Perto", checked: detalhesConsulta.sintomas_dif_vp },
    { label: "Dif. Visão Longe", checked: detalhesConsulta.sintomas_dif_vl },
    {
      label: "Dor de Cabeça",
      checked: detalhesConsulta.sintomas_dor_de_cabeca,
    },
    {
      label: "Dor nos Olhos",
      checked: detalhesConsulta.sintomas_dor_nos_olhos,
    },
    {
      label: "Lacrimejamento",
      checked: detalhesConsulta.sintomas_lacrimejamento,
    },
    { label: "Outro", checked: detalhesConsulta.sintomas_outro },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Title variant="h6" component="h3">
            {title}
          </Title>
        </Grid>
        {items.map((item, index) => (
          <Grid item xs={6} md={1} key={index}>
            <Typography variant="body1" component="p">
              {item.label}
            </Typography>
            <FormControlLabel
              control={<Switch disabled checked={item.checked} />}
              label=""
              style={{ marginLeft: "auto" }}
            />
          </Grid>
        ))}
        {detalhesConsulta.nome_sintoma_outro && (
          <Grid item xs={6} md={4}>
            <Typography variant="body1" component="p">
              Nome:
            </Typography>
            {detalhesConsulta.nome_sintoma_outro}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const DetalhesAntecedentes = ({ title, detalhesConsulta }) => {
  const items = [
    {
      label: "Cirurgia de Catarata",
      checked: detalhesConsulta.antecedentes_cirurgia_catarata,
    },
    {
      label: "Cirurgia de Pterígio",
      checked: detalhesConsulta.antecedentes_cirurgia_pterigio,
    },
    { label: "Trauma", checked: detalhesConsulta.antecedentes_trauma },
    { label: "Outros", checked: detalhesConsulta.antecedentes_outro },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Title>{title}</Title>
        </Grid>
        {items.map((item, index) => (
          <Grid item xs={6} md={1} key={index}>
            <Typography variant="body1" component="p">
              {item.label}
            </Typography>
            <FormControlLabel
              control={<Switch disabled checked={item.checked} />}
              label=""
              style={{ marginLeft: "auto" }}
            />
          </Grid>
        ))}
        {detalhesConsulta.nome_antecedente_outro && (
          <Grid item xs={6} md={4}>
            <Typography variant="body1" component="p">
              Nome:
            </Typography>
            {detalhesConsulta.nome_antecedente_outro}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const DetalhesRx = ({ title, detalhesConsulta }) => {
  const items = [
    { label: "Óculos", checked: detalhesConsulta.rx_oculos },
    { label: "LCG", checked: detalhesConsulta.rx_lcg },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Title>{title}</Title>
          </Grid>
          {items.map((item, index) => (
            <Grid item xs={6} md={1} key={index}>
              <Typography variant="body1" component="p">
                {item.label}
              </Typography>
              <FormControlLabel
                control={<Switch disabled checked={item.checked} />}
                label=""
                style={{ marginLeft: "auto" }}
              />
            </Grid>
          ))}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Esférico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Cilíndrico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Eixo
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  A.V.
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.D.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_od_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_od_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_od_eixo || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_od_av || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.E.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_oe_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_oe_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_oe_eixo || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_oe_av || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  ADD
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_add_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_add_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_add_eixo || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_detalhes_add_av || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant="body1" component="p" style={{ marginTop: "20px" }}>
          Tipo Lente:
          {detalhesConsulta.tipo_lente || "-"}
        </Typography>
      </Box>
    </Paper>
  );
};

const DetalhesBiomicroscopia = ({ title, detalhesConsulta }) => {
  const items = [
    {
      label: "Sobrancelha",
      value: detalhesConsulta.biomicroscopia_sobrancelha,
    },
    { label: "Cílios", value: detalhesConsulta.biomicroscopia_cilios },
    { label: "Pálpebras", value: detalhesConsulta.biomicroscopia_palpebras },
    {
      label: "Conjuntivas",
      value: detalhesConsulta.biomicroscopia_conjuntivas,
    },
    { label: "Córneas", value: detalhesConsulta.biomicroscopia_corneas },
    { label: "Íris", value: detalhesConsulta.biomicroscopia_iris },
    {
      label: "Observações",
      value: detalhesConsulta.biomicroscopia_observacoes,
    },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Title>{title}</Title>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={12} key={index}>
            <Typography variant="body1" component="p">
              {item.label}: {item.value || "-"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const DetalhesCeratometria = ({ title, detalhesConsulta }) => {
  const items = [
    { label: "O.D.", value: detalhesConsulta.ceratometria_od },
    { label: "O.E.", value: detalhesConsulta.ceratometria_oe },
    {
      label: "Observações",
      value: detalhesConsulta.ceratometria_observacoes,
    },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Title>{title}</Title>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={12} key={index}>
            <Typography variant="body1" component="p">
              {item.label}: {item.value || "-"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const DetalhesRetinoscopia = ({ title, detalhesConsulta }) => {
  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Title>{title}</Title>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Esférico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Cilíndrico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Eixo
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.D.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_od_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_od_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_od_eixo || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.E.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_oe_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_oe_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.retinoscopia_oe_eixo || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

const DetalhesRxFinal = ({ title, detalhesConsulta }) => {
  const items = [
    { label: "Monofocal", checked: detalhesConsulta.rx_final_monofocal },
    { label: "Bifocal", checked: detalhesConsulta.rx_final_bifocal },
    { label: "Multifocal", checked: detalhesConsulta.rx_final_multifocal },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Title>{title}</Title>
          </Grid>
          {items.map((item, index) => (
            <Grid item xs={6} md={1} key={index}>
              <Typography variant="body1" component="p">
                {item.label}
              </Typography>
              <FormControlLabel
                control={<Switch disabled checked={item.checked} />}
                label=""
                style={{ marginLeft: "auto" }}
              />
            </Grid>
          ))}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Esférico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Cilíndrico
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  Eixo
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  A.V.L.
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1" component="p">
                  A.V.P.
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.D.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_od_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_od_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_od_eixo || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_od_evl || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_od_avp || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body1" component="p">
                  O.E.
                </Typography>
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_oe_esferico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_oe_cilindrico || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_oe_eixo || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_oe_evl || "-"}
              </TableCell>
              <TableCell align="center">
                {detalhesConsulta.rx_final_oe_avp || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant="body1" component="p" style={{ marginTop: "20px" }}>
          ADD:
          {detalhesConsulta.rx_final_add || "-"}
        </Typography>
      </Box>
    </Paper>
  );
};

const DetalhesResumo = ({ title, detalhesConsulta }) => {
  const items = [
    {
      label: "Astigmatismo",
      checked: detalhesConsulta.resumo_clinico_astigmatismo,
    },
    {
      label: "Hipermetropia",
      checked: detalhesConsulta.resumo_clinico_hipermetropia,
    },
    { label: "Miopia", checked: detalhesConsulta.resumo_clinico_miopia },
    {
      label: "Presbiopia",
      checked: detalhesConsulta.resumo_clinico_presbiopia,
    },
  ];

  return (
    <Paper
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "20px",
        marginTop: "20px",
        overflowX: "auto",
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Title>{title}</Title>
          </Grid>
          {items.map((item, index) => (
            <Grid item xs={6} md={1} key={index}>
              <Typography variant="body1" component="p">
                {item.label}
              </Typography>
              <FormControlLabel
                control={<Switch disabled checked={item.checked} />}
                label=""
                style={{ marginLeft: "auto" }}
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" component="p" style={{ marginTop: "20px" }}>
          {detalhesConsulta.resumo_clinico || "-"}
        </Typography>
      </Box>
    </Paper>
  );
};

export const DetalhesConsulta = () => {
  const [detalhesConsulta, setDetalhesConsulta] = useState(null);
  const [medico, setMedico] = useState("");
  const [data, setData] = useState("");

  const user = decryptUser();
  const showAcess = user?.nivelAcesso <= 2;

  useEffect(() => {
    setDetalhesConsulta(JSON.parse(localStorage.getItem("detalhesConsulta")));
    setMedico(localStorage.getItem("medico"));
    setData(localStorage.getItem("data"));

    return () => {
      localStorage.removeItem("detalhesConsulta");
      localStorage.removeItem("medico");
      localStorage.removeItem("data");
    };
  }, []);

  console.log("detalhesConsulta: ", detalhesConsulta);
  if (!detalhesConsulta) return <div>Carregando...</div>;

  return (
    <Box style={{ padding: "20px" }}>
      <Cabecalho medico={medico} data={data} />
      {showAcess ? (
        <>
          <DetalhesMedicamentos
            title={"Medicamentos"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesSintomas
            title={"Sintomas"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesAntecedentes
            title={"Antecedentes"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesRx
            title={"Usuário de RX"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesBiomicroscopia
            title={"Biomicroscopia"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesCeratometria
            title={"Ceratometria"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesRetinoscopia
            title={"Retinoscopia"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesRxFinal
            title={"RX Final"}
            detalhesConsulta={detalhesConsulta}
          />
          <DetalhesResumo
            title={"Resumo Clínico"}
            detalhesConsulta={detalhesConsulta}
          />
        </>
      ) : (
        <>
          <DetalhesRxFinal detalhesConsulta={detalhesConsulta} />
          <DetalhesResumo detalhesConsulta={detalhesConsulta} />
        </>
      )}
    </Box>
  );
};
